import { ref, reactive } from 'vue';
import {
  doc,
  collection,
  query,
  where,
  getDocs,
  getDoc,
  addDoc,
  updateDoc,
  deleteDoc,
} from 'firebase/firestore';
import _ from 'lodash';
import { db } from '@/assets/firebase';

export default function useUserProjects(userUid) {
  const projects = ref([]);
  const project = ref({});
  const data = reactive({ projects });
  const state = reactive({
    isError: false,
    isLoading: false,
    errorMessage: '',
  });
  async function getProjects(cb) {
    data.projects = [];
    state.isLoading = true;
    try {
      // 搜尋為「擁有者」的計畫
      const q1 = query(collection(db, 'projects'), where('admin', '==', userUid));
      let querySnapshot = await getDocs(q1);
      querySnapshot.forEach((result) => {
        const projectData = _.cloneDeep(result.data());
        projectData.id = result.id;
        projectData.isAdmin = true; // 對使用者為擁有者的計畫進行標記
        data.projects.push(projectData);
      });
      // 搜尋為「管理者」的計畫
      const q2 = query(collection(db, 'projects'), where('coAdmin', 'array-contains', userUid));
      querySnapshot = await getDocs(q2);
      querySnapshot.forEach((result) => {
        const projectData = _.cloneDeep(result.data());
        projectData.id = result.id;
        projectData.isAdmin = false;
        data.projects.push(projectData);
      });
      // 搜尋為「訪視者」的計畫
      const q3 = query(collection(db, 'projects'), where('coop', 'array-contains', userUid));
      querySnapshot = await getDocs(q3);
      querySnapshot.forEach((result) => {
        const projectData = _.cloneDeep(result.data());
        projectData.id = result.id;
        projectData.isAdmin = false;
        data.projects.push(projectData);
      });
      // 搜尋為「檢視者」的計畫
      const q4 = query(collection(db, 'projects'), where('share', 'array-contains', userUid));
      querySnapshot = await getDocs(q4);
      querySnapshot.forEach((result) => {
        const projectData = _.cloneDeep(result.data());
        projectData.id = result.id;
        projectData.isAdmin = false;
        data.projects.push(projectData);
      });
      state.isLoading = false;
      if (cb) {
        cb();
      }
    } catch (e) {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = e.message;
      if (cb) {
        cb();
      }
    }
  }
  async function getProject(id, cb) {
    state.isLoading = true;
    try {
      const docRef = doc(db, 'projects', id);
      const docSnap = await getDoc(docRef);
      project.value = _.cloneDeep(docSnap.data());
      state.isLoading = false;
      if (cb) {
        cb();
      }
    } catch (e) {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = e.message;
      if (cb) {
        cb();
      }
    }
  }
  async function addProject(values, cb) {
    const now = Date.now();
    // 準備計畫物件
    const projectData = {
      name: values.projectName,
      createdAt: now,
      updatedAt: now,
      admin: userUid,
      coAdmin: [],
      coop: [],
      share: [],
    };
    state.isLoading = true;
    try {
      await addDoc(collection(db, 'projects'), projectData);
      state.isLoading = false;
      getProjects();
      if (cb) {
        cb();
      }
    } catch (e) {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = e.message;
      if (cb) {
        cb();
      }
    }
  }
  async function updateProject(id, newProject, cb) {
    state.isLoading = true;
    try {
      const docRef = doc(db, 'projects', id);
      await updateDoc(docRef, newProject);
      state.isLoading = false;
      if (cb) {
        cb();
      }
    } catch (e) {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = e.message;
      if (cb) {
        cb();
      }
    }
  }
  async function deleteProject(id, cb) {
    state.isLoading = true;
    try {
      await deleteDoc(doc(db, 'projects', id));
      state.isLoading = false;
      // 刪除成功，重新讀取計畫列表
      getProjects();
      if (cb) {
        cb();
      }
    } catch (e) {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = e.message;
      if (cb) {
        cb();
      }
    }
  }
  return {
    project,
    projects,
    state,
    getProjects,
    getProject,
    addProject,
    updateProject,
    deleteProject,
  };
}
