<template>
  <div class="container">
    <VueLoading v-model:active="state.isLoading" color="#196bb0"></VueLoading>
    <div class="jo-breadcrumb d-flex align-items-center py-2 mb-3">
      <button type="button" class="nav-link me-3" @click="this.$router.push('/projects')">
        <font-awesome-icon :icon="['fas', 'arrow-left']" class="nav-icon" />
        上一頁
      </button>
      <p class="mb-0">
        計畫管理
        <font-awesome-icon :icon="['fas', 'angle-double-right']" class="ms-1 me-1" />
        {{ projectOriginName }}
      </p>
    </div>
    <VeeForm class="project-form row" id="project-edit-form">
      <div class="col-3">
        <h2 class="text-center">
          <font-awesome-icon :icon="['fas', 'info-circle']" class="me-2" />計畫資訊
        </h2>
        <div>
          <label for="projectName" class="form-label">計畫名稱：</label>
          <Field
            class="form-control"
            id="projectName"
            name="projectName"
            :rules="isRequired"
            v-model="project.name"
            @keyup="isEdited = true"
          />
          <ErrorMessage name="projectName" class="ms-1 text-danger" />
        </div>
        <div class="mt-3">建立時間：{{ getDateString(project.createdAt) }}</div>
        <div class="mt-3">更新時間：{{ getDateString(project.updatedAt) }}</div>
        <div class="mt-3">擁有者：{{ projectAdmin.name }}</div>
      </div>
      <div class="col-3">
        <h2 class="text-center">
          <font-awesome-icon :icon="['fas', 'user-cog']" class="me-2" />管理者
        </h2>
        <ul class="list-group">
          <li
            class="list-group-item d-flex align-items-center"
            v-for="(coAdmin, key) in projectCoAdmin"
            :key="'coAdmin_' + key"
          >
            {{ coAdmin.name }}
            <font-awesome-icon
              :icon="['fas', 'user-slash']"
              class="ms-auto delete-icon"
              @click="deleteUser(projectCoAdmin, key, 'coAdmin')"
              v-if="userCharacter === 'admin'"
            />
          </li>
        </ul>
        <center>
          <font-awesome-icon
            v-show="!addingCoAdmin"
            :icon="['fas', 'plus-circle']"
            class="add-icon nav-icon mt-3"
            @click.prevent="changeAddCharInput('CoAdmin')"
            v-if="userCharacter === 'admin'"
          />
        </center>
        <div class="d-flex align-items-center mt-3">
          <input
            type="text"
            class="form-control me-1"
            placeholder="請輸入Email"
            v-model="addingCoAdminEmail"
            ref="addCoAdminInput"
            v-show="addingCoAdmin"
            @keyup.enter="addUser('CoAdmin')"
          />
          <div class="btn-group" role="group" v-show="addingCoAdmin">
            <button
              type="button"
              class="btn btn-outline-primary"
              @click.prevent="addUser('CoAdmin')"
            >
              <font-awesome-icon :icon="['fas', 'check-circle']" class="nav-icon" />
            </button>
            <button
              type="button"
              class="btn btn-outline-danger"
              @click.prevent="addingCoAdmin = false"
            >
              <font-awesome-icon :icon="['fas', 'times-circle']" class="nav-icon" />
            </button>
          </div>
        </div>
      </div>
      <div class="col-3">
        <h2 class="text-center">
          <font-awesome-icon :icon="['fas', 'user-edit']" class="me-2" />訪視者
        </h2>
        <ul class="list-group">
          <li
            class="list-group-item d-flex align-items-center"
            v-for="(coop, key) in projectCoop"
            :key="'coop_' + key"
          >
            {{ coop.name }}
            <font-awesome-icon
              :icon="['fas', 'user-slash']"
              class="ms-auto delete-icon"
              @click="deleteUser(projectCoop, key, 'coop')"
              v-if="userCharacter === 'admin' || userCharacter === 'coAdmin'"
            />
          </li>
        </ul>
        <center>
          <font-awesome-icon
            v-show="!addingCoop"
            :icon="['fas', 'plus-circle']"
            class="add-icon nav-icon mt-3"
            @click.prevent="changeAddCharInput('Coop')"
            v-if="userCharacter === 'admin' || userCharacter === 'coAdmin'"
          />
        </center>
        <div class="d-flex align-items-center mt-3">
          <input
            type="text"
            class="form-control me-1"
            placeholder="請輸入Email"
            v-model="addingCoopEmail"
            ref="addCoopInput"
            v-show="addingCoop"
            @keyup.enter="addUser('Coop')"
          />
          <div class="btn-group" role="group" v-show="addingCoop">
            <button type="button" class="btn btn-outline-primary" @click.prevent="addUser('Coop')">
              <font-awesome-icon :icon="['fas', 'check-circle']" class="nav-icon" />
            </button>
            <button
              type="button"
              class="btn btn-outline-danger"
              @click.prevent="addingCoop = false"
            >
              <font-awesome-icon :icon="['fas', 'times-circle']" class="nav-icon" />
            </button>
          </div>
        </div>
      </div>
      <div class="col-3">
        <h2 class="text-center"><font-awesome-icon :icon="['fas', 'eye']" class="me-2" />檢視者</h2>
        <ul class="list-group">
          <li
            class="list-group-item d-flex align-items-center"
            v-for="(share, key) in projectShare"
            :key="'share_' + key"
          >
            {{ share.name }}
            <font-awesome-icon
              :icon="['fas', 'user-slash']"
              class="ms-auto delete-icon"
              @click.prevent="deleteUser(projectShare, key, 'share')"
              v-if="userCharacter === 'admin' || userCharacter === 'coAdmin'"
            />
          </li>
        </ul>
        <center>
          <font-awesome-icon
            v-show="!addingShare"
            :icon="['fas', 'plus-circle']"
            class="add-icon nav-icon mt-3"
            @click.prevent="changeAddCharInput('Share')"
            v-if="userCharacter === 'admin' || userCharacter === 'coAdmin'"
          />
        </center>
        <div class="d-flex align-items-center mt-3">
          <input
            type="text"
            class="form-control me-1"
            placeholder="請輸入Email"
            v-model="addingShareEmail"
            ref="addShareInput"
            v-show="addingShare"
            @keyup.enter="addUser('Share')"
          />
          <div class="btn-group" role="group" v-show="addingShare">
            <button type="button" class="btn btn-outline-primary" @click.prevent="addUser('Share')">
              <font-awesome-icon :icon="['fas', 'check-circle']" class="nav-icon" />
            </button>
            <button
              type="button"
              class="btn btn-outline-danger"
              @click.prevent="addingShare = false"
            >
              <font-awesome-icon :icon="['fas', 'times-circle']" class="nav-icon" />
            </button>
          </div>
        </div>
      </div>
      <div
        class="text-center mt-3"
        v-if="userCharacter === 'admin' || userCharacter === 'coAdmin'"
        v-show="isEdited"
      >
        <button type="button" class="btn btn-primary me-3" @click.prevent="useUpdateProject">
          儲存
        </button>
        <button type="button" class="btn btn-secondary" @click.prevent="useGetProject">
          重置
        </button>
      </div>
    </VeeForm>
    <div class="cases-list row mt-3">
      <h2 class="text-center py-3 mb-3">個案列表</h2>
      <ul class="row list-unstyled">
        <li class="col-3 mb-3" v-for="(caseData, key) in cases" :key="'caseData_' + key">
          <button
            type="button"
            class="btn btn-primary w-100"
            @click.prevent="$router.push(`/case/${projectId}/${caseData.id}`)"
          >
            {{ caseData.name }}
          </button>
        </li>
        <li class="col-3 mb-3" v-if="userCharacter !== 'share'">
          <button
            type="button"
            class="btn btn-outline-secondary w-100"
            @click.prevent="$router.push(`/newcase/${projectId}`)"
          >
            <font-awesome-icon :icon="['fas', 'plus-circle']" class="nav-icon me-2" />
            新增個案
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
// 載入 Vue 3 composition api
import { ref, toRefs } from 'vue';
// 載入 vue router
import { useRoute, useRouter } from 'vue-router';
// 載入 loading overlay
import VueLoading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
// 載入 firestore
// eslint-disable-next-line object-curly-newline
import { query, collection, where, getDocs } from 'firebase/firestore';
// 載入 lodash
import _ from 'lodash';
// 載入 vee-validate
import { Form as VeeForm, Field, ErrorMessage } from 'vee-validate';
import { db } from '@/assets/firebase';
// 載入 sweetalert2
import useVueSweetAlert2 from '@/composables/useVueSweetAlert2';
import useUserProjects from '@/composables/useUserProjects';
import useUserInfo from '@/composables/useUserInfo';

export default {
  components: {
    VeeForm,
    Field,
    ErrorMessage,
    VueLoading,
  },
  props: {
    userUid: String,
    userEmail: String,
  },
  data() {
    return {
      cases: [],
    };
  },
  setup(props) {
    const $swal = useVueSweetAlert2();
    const route = useRoute();
    const router = useRouter();
    // ================================
    const { userUid } = toRefs(props);
    // eslint-disable-next-line object-curly-newline
    const { state, project, getProject, updateProject } = useUserProjects(userUid.value);
    const { getUserInfo } = useUserInfo();
    const isEdited = ref(false);
    // ================================ 計畫內容
    const projectId = ref(route.params.id);
    const projectOriginName = ref('');
    const projectAdmin = ref({});
    const projectCoAdmin = ref([]);
    const projectCoop = ref([]);
    const projectShare = ref([]);
    const userCharacter = ref('');
    const addingCoAdmin = ref(false);
    const addingCoAdminEmail = ref('');
    const addingCoop = ref(false);
    const addingCoopEmail = ref('');
    const addingShare = ref(false);
    const addingShareEmail = ref('');

    function checkCurrentUserCharacter() {
      if (project.value.admin === userUid.value) {
        userCharacter.value = 'admin';
      } else if (project.value.coAdmin.indexOf(userUid.value) !== -1) {
        userCharacter.value = 'coAdmin';
      } else if (project.value.coop.indexOf(userUid.value) !== -1) {
        userCharacter.value = 'coop';
      } else if (project.value.share.indexOf(userUid.value) !== -1) {
        userCharacter.value = 'share';
      } else {
        router.push('/projects');
      }
    }
    function getAllUserInfo() {
      // 清空使用者陣列
      projectCoAdmin.value = [];
      projectCoop.value = [];
      projectShare.value = [];
      // 讀取擁有者(Admin)資料
      getUserInfo(project.value.admin, (userData) => {
        projectAdmin.value = _.cloneDeep(userData);
      });
      // 讀取管理者(CoAdmin)資料
      if (project.value.coAdmin.length > 0) {
        project.value.coAdmin.forEach((id) => {
          getUserInfo(id, (userData) => {
            projectCoAdmin.value.push(userData);
          });
        });
      }
      // 讀取訪視者(Coop))資料
      if (project.value.coop.length > 0) {
        project.value.coop.forEach((id) => {
          getUserInfo(id, (userData) => {
            projectCoop.value.push(userData);
          });
        });
      }
      // 讀取檢視者(Share)資料
      if (project.value.share.length > 0) {
        project.value.share.forEach((id) => {
          getUserInfo(id, (userData) => {
            projectShare.value.push(userData);
          });
        });
      }
      checkCurrentUserCharacter();
    }
    function useGetProject() {
      isEdited.value = false;
      getProject(projectId.value, () => {
        if (state.isError === true) {
          // 錯誤發生
          state.isError = false;
          $swal.fire({
            text: String(state.errorMessage),
            icon: 'error',
          });
        } else {
          // 儲存計畫案原本的名字
          projectOriginName.value = project.value.name;
          // 完成計畫案讀取，開始讀取該計畫相關之所有使用者資料
          getAllUserInfo();
        }
      });
    }
    function useUpdateProject() {
      const now = Date.now();
      // 更新最後更新時間
      project.value.updatedAt = now;
      updateProject(projectId.value, project.value, () => {
        if (state.isError === true) {
          // 錯誤發生
          state.isError = false;
          $swal.fire({
            text: String(state.errorMessage),
            icon: 'error',
          });
        } else {
          isEdited.value = false;
          projectOriginName.value = project.value.name;
          $swal.fire({
            text: '修改完成',
            icon: 'success',
          });
        }
      });
    }
    // 建立前先讀取計畫資料
    useGetProject();

    return {
      state,
      project,
      projectId,
      projectOriginName,
      projectAdmin,
      projectCoAdmin,
      projectCoop,
      projectShare,
      isEdited,
      userCharacter,
      addingCoAdmin,
      addingCoAdminEmail,
      addingCoop,
      addingCoopEmail,
      addingShare,
      addingShareEmail,
      // function
      useGetProject,
      useUpdateProject,
    };
  },
  methods: {
    async getCases() {
      // 清空 cases
      this.cases = [];

      const loader = this.$loading.show();
      try {
        // 取得所有該計畫的個案
        const querySnapshot = await getDocs(collection(db, 'projects', this.projectId, 'cases'));
        querySnapshot.forEach((caseData) => {
          const casesData = _.cloneDeep(caseData.data());
          casesData.id = caseData.id;
          this.cases.push(casesData);
        });
        this.cases.sort((a, b) => {
          if (a.sn > b.sn) {
            return 1;
          }
          if (a.sn < b.sn) {
            return -1;
          }
          return 0;
        });
        loader.hide();
      } catch (e) {
        loader.hide();
        this.$swal({
          text: String(e),
          icon: 'error',
        });
      }
    },
    async addUser(type) {
      // 判斷是否新增自己
      if (this[`adding${type}Email`] === this.userEmail) {
        this.$swal({
          text: '您新增的使用者為自己，請重新輸入',
          icon: 'error',
        });
        this[`adding${type}Email`] = '';
      } else if (this.checkIfRepeat(type)) {
        // 判斷是否新增已經有的使用者
        this.$swal({
          text: '您新增的使用者已重複，請重新輸入',
          icon: 'error',
        });
        this[`adding${type}Email`] = '';
      } else {
        const email = this[`adding${type}Email`];
        const loader = this.$loading.show();
        try {
          // 資料庫搜尋使用者
          const q = query(collection(db, 'users'), where('email', '==', email));
          const querySnapshot = await getDocs(q);
          // 如果 size 為 0 表示搜尋不到，清空輸入
          if (querySnapshot.size === 0) {
            this.$swal({
              text: '搜尋不到使用者，請重新輸入',
              icon: 'error',
              returnFocus: false,
            }).then(() => {
              this.$refs[`add${type}Input`].focus();
            });
          } else {
            // 搜尋到了，複製使用者資料到對應的 projectArray
            querySnapshot.forEach((user) => {
              const userData = _.cloneDeep(user.data());
              userData.id = user.id;
              this[`project${type}`].push(userData);
              // 複製使用者 uid 到 project 物件內
              const lowercaseType = type.charAt(0).toLowerCase() + type.slice(1);
              this.project[lowercaseType].push(user.id);
            });
            // 複製完畢後，隱藏新增使用者的輸入框
            this[`adding${type}`] = false;
            // 順利新增使用者，故修改 isEdited 狀態
            this.isEdited = true;
          }
          // 不論如何，清空新增使用者輸入框
          this[`adding${type}Email`] = '';
          loader.hide();
        } catch (e) {
          loader.hide();
          this.$swal({
            text: String(e),
            icon: 'error',
          });
        }
      }
    },
    deleteUser(target, key, type) {
      this.$swal({
        text: `您確定要刪除"${target[key].name}"嗎？`,
        icon: 'warning',
        confirmButtonText: '確定',
        cancelButtonText: '取消',
        showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          // 確定刪除，將該使用者從 project 物件及 該類別使用者陣列刪除
          this.project[type].splice(key, 1);
          target.splice(key, 1);
          // 順利刪除使用者，故修改 isEdited 狀態
          this.isEdited = true;
        }
      });
    },
    changeAddCharInput(type) {
      // 重置狀態
      this.addingCoAdmin = false;
      this.addingCoop = false;
      this.addingShare = false;
      this.addingCoAdminEmail = '';
      this.addingCoopEmail = '';
      this.addingShareEmail = '';
      // 將要求的 type 顯示
      this[`adding${type}`] = true;
    },
    checkIfRepeat(type) {
      // 檢查新增的角色是否重複新增
      let ifRepeat = false;
      this.projectCoAdmin.forEach((user) => {
        if (this[`adding${type}Email`] === user.email) {
          ifRepeat = true;
        }
      });
      this.projectCoop.forEach((user) => {
        if (this[`adding${type}Email`] === user.email) {
          ifRepeat = true;
        }
      });
      this.projectShare.forEach((user) => {
        if (this[`adding${type}Email`] === user.email) {
          ifRepeat = true;
        }
      });
      return ifRepeat;
    },
    getDateString(timestamp) {
      const date = new Date(timestamp);
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      let hour = date.getHours();
      let minute = date.getMinutes();
      if (month < 10) {
        month = `0${month}`;
      }
      if (day < 10) {
        day = `0${day}`;
      }
      if (hour < 10) {
        hour = `0${hour}`;
      }
      if (minute < 10) {
        minute = `0${minute}`;
      }
      // 檢測是否為 NaN
      if (Number.isNaN(year)) {
        year = '0000';
      }
      if (Number.isNaN(month)) {
        month = '00';
      }
      if (Number.isNaN(day)) {
        day = '00';
      }
      if (Number.isNaN(hour)) {
        hour = '00';
      }
      if (Number.isNaN(minute)) {
        minute = '00';
      }
      return `${year}年${month}月${day}日 ${hour}:${minute}`;
    },
    isRequired(value) {
      return value ? true : '此為必填項目';
    },
  },
  mounted() {
    this.getCases();
  },
  // 如果使用者有編輯，則離開前詢問使用者是否確定離開
  beforeRouteLeave(to, from, next) {
    if (this.isEdited) {
      this.$swal({
        text: '您有未儲存的編輯，是否確定離開？',
        icon: 'warning',
        confirmButtonText: '確定',
        cancelButtonText: '取消',
        showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          next();
        } else {
          next(false);
        }
      });
    } else {
      next();
    }
  },
};
</script>

<style lang="scss" scoped>
.container {
  color: $primary;
}
.nav-link {
  color: $primary;
  font-size: 1rem;
  border-radius: 0.5rem;
  margin-right: 0.5rem;
  cursor: pointer;
  border: none;
  background-color: $bright;
  .nav-icon {
    color: $primary;
    font-size: 1rem;
    margin-bottom: -5px;
    margin: 0 0.5rem 0 0;
  }
  &:hover {
    background-color: $primary;
    text-decoration: none;
    color: white;
    .nav-icon {
      color: white;
    }
  }
}
.jo-breadcrumb {
  border-bottom: 2px solid $primary;
}
.project-form {
  .col-3 {
    border-right: 1px solid $primary;
    &:nth-child(4) {
      border-right: none;
    }
  }
  h2 {
    font-size: 1.625rem;
  }
}
.list-group-item {
  color: $primary;
}
.delete-icon,
.add-icon {
  color: rgba($primary, 0.5);
  cursor: pointer;
  &:hover {
    color: $primary;
  }
}
.cases-list {
  h2 {
    border-top: 2px solid $primary;
    border-bottom: 2px solid $primary;
  }
}
</style>
